
export const ENABLE_RANKS = ["S", "A", "B", "C"];
export const ENABLE_RANKS_LOWER = ["s", "a", "b", "c"];

export const VERIFY_DOCS = {
    'driver' : '運転免許証',
    'health' : '健康保険証',
    'student' : '学生証(顔写真付)',
    'passport' : 'パスポート',
    'special_residence' : '特別永住者証明書',
    'my_number' : 'マイナンバー',
    'juuki' : '住民基本カード',
    'driver2' : '運転経歴証明書',
    'other' : 'その他',
};

export const JOBS = {
    'company_employee' : '会社員',
    'contract_employee' : '契約社員・派遣社員',
    'part_time' : 'パート・アルバイト',
    'public_servant' : '公務員',
    'self_employed' : '自営業',
    'homemaker' : '専業主婦・主夫',
    'student' : '学生',
    'unemployed' : '無職',
    'other' : 'その他',
};

export const GENDERS = {
    'f' : '女性',
    'm' : '男性',
    'o' : 'その他',
};

export const getCharLength = (char) => {
    // eslint-disable-next-line no-control-regex
    return /[\x00-\xff]/.test(char) ? 1 : 2;
}

export const getCellValueWidth = (cellValue) => {
    let totalLength = 0;
    for (let char of cellValue) {
        totalLength += getCharLength(char);
    }
    return totalLength;
}

export const getColWidths = (searchResults, CNAMES) => {
    const colWidths = {};
    for (let colIndex in CNAMES) {
        let maxColWidth = 0;
        for (let rowIndex = 0; rowIndex < searchResults.length; rowIndex++) {
            const rowData = searchResults[rowIndex];
            const cellValue = rowData[CNAMES[colIndex]];
            const cellWidth = cellValue ? (getCellValueWidth(cellValue.toString()) * 7.5) + 20 : 50; // 7.5は全角文字の1文字当たりの平均幅の半分、10は余白
            if (cellWidth > maxColWidth) {
                maxColWidth = cellWidth;
            }
        }
        colWidths[CNAMES[colIndex]] = maxColWidth;
    }
    return colWidths;
}

export const toNumbers = (orgValue) => {
    return orgValue.replace(/\D/g, '');
}

export const getTimeStrOfIso = () => {
    return new Date().toISOString();
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() は0から始まるため、1を加える
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
}

export const getTodayDate1 = (sep="-") => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() は0から始まるため、1を加える
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}${sep}${month}${sep}${day}`;
}

export const getTodayDate2 = () => {
    //12時までは前日の日付を返す
    let date = new Date();
    const hours = date.getHours();
    if (hours < 12) {
        date = new Date(date.setDate(date.getDate() - 1));
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() は0から始まるため、1を加える
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const getTodayDate3 = () => {
    const date = new Date();
    const year = (date.getFullYear()).toString().substring(2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() は0から始まるため、1を加える
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}${month}${day}`;
}

export const zenkakuToHankaku = (str) => {
    if (typeof(str) !== "string") {
        return str;
    }
    str = str.replace(/[^0-9０-９-]/g, '');
    return str.replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
}

export const formatYen = (yenStr) => {
    const formatter = new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY'
    });
    return formatter.format(yenStr)
}

export const decodeFromBase64 = (base64Str) => {
    const uriEncodedStr = atob(base64Str); // Base64エンコードされた文字列をデコード
    return decodeURIComponent(uriEncodedStr); // URIコンポーネントをデコードして元の文字列に戻す
}

export const decodeCustomerJson = (customerJson) => {
    const customerObj = JSON.parse(customerJson);
    customerObj["n"] = decodeFromBase64(customerObj["n"]);
    return customerObj;
}

export const DEVICE_IPOHNE = "iPhone";
export const DEVICE_IPAD = "iPad";
export const DEVICE_WINDOWS = "Windows PC";
export const getDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('iphone')) {
        return 'iPhone'
    } else if (userAgent.includes('ipad')) {
        return 'iPad'
    } else if (userAgent.includes('windows')) {
        return 'Windows PC'
    }
}

export const createNewId = ()  => {
    return `id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
}

export const generateRandomStr = (strLength)  => {
    return `${Math.random().toString(36).substr(2, strLength)}`;
}

export const currentYear = new Date().getFullYear();
export const yearOptions = [];
for (let i = 1900; i <= currentYear; i++) {
    yearOptions.push(i);
}
export const monthOptions = [];
for (let i = 1; i <= 12; i++) {
    monthOptions.push(i);
}
export const dayOptions = [];
for (let i = 1; i <= 31; i++) {
    dayOptions.push(i);
}

export const formatToTwoDigits = num => {
    if (typeof num === "string" && num.length === 2) { // numが文字列であり、かつ2桁の場合、そのまま返す
        return num;
    }
    return num < 10 ? `0${num}` : String(num); // numが数値、または数値に変換可能な文字列であり、10未満の場合、0を先頭に付ける
};

export const formatMonthDayToNumber = num => {
    if (num.length === 2) {
        // 2桁の場合、1桁目が0なら2桁目を返す
        if (num[0] === "0") {
            return num[1];
        } else {
            return num;
        }
    } else {
        return num;
    }
};

export const validateFileSize = (file, maxFileSize) => {
    return file.size <= maxFileSize;
};
