import React, {useState} from 'react';
import { auth } from '../firebaseConfig';
import { sendPasswordResetEmail  } from 'firebase/auth';
import Header from "./Header";
import Footer from "./Footer";

function PasswordReset () {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleResetPassword = async (event) => {
        event.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('パスワード初期化メールを送信しました。メール内のリンクをクリックしてパスワードを再設定してください。');
        } catch (error) {
            setMessage("パスワード初期化メール送信失敗: " + error.message);
        }
    };

    return (
        <div style={{textAlign: 'center'}}>
            <Header/>
            <h2>パスワード初期化</h2>
            <form onSubmit={handleResetPassword}>
                <div className="container mt-5">
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <th>メールアドレス</th>
                            <td>
                                <input
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{width: '100%'}}
                                    required
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <button type="submit">パスワード初期化</button>
                {message && <p>{message}</p>}
            </form>
            <Footer/>
        </div>
    );
}

export default PasswordReset;
