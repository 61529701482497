import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { sendEmailVerification } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import Header from "./Header";
import Footer from "./Footer";

const Top = () => {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null); // <-- 1. 新しいstateを追加
    const [emailSent, setEmailSent] = useState(false);
    const isVip = userData && userData.is_vip;

    // ユーザー情報を取得
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                // <-- 2. ログインしたユーザーのFirestoreドキュメントを取得
                const docRef = doc(db, "users", currentUser.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserData(docSnap.data());
                } else {
                    console.log("No such document!");
                }
            }
        });

        return () => unsubscribe();
    }, []);

    // 確認メールを再送信
    const resendVerificationEmail = async (e) => {
        if (user && !user.emailVerified) {
            try {
                e.target.disabled = true;
                const actionCodeSettings = {
                    url: 'https://shinsoku-tcg.com/'
                };
                await sendEmailVerification(user, actionCodeSettings); // メール確認メールを送信
                setEmailSent(true);
            } catch (error) {
                if (error.code === 'auth/too-many-requests') {
                    alert('連続で送信できません。しばらくお待ちください。');
                } else {
                    alert('エラーが発生しました。');
                }
                e.target.disabled = false;
            }
        }
    };

    // ログアウト
    const handleLogout = async () => {
        await auth.signOut();
    };

    if (!user) { // ログインしていない場合
        return (
            <div style={{ textAlign: 'center' }}>
                <Header />
                <div><Link to="/register" className="menu">会員登録</Link></div>
                <br />
                <div><Link to="/login" className="menu">ログイン</Link></div>
                <Footer />
            </div>
        );
    }

    if (!user.emailVerified) { // メールアドレスが未認証の場合
        return (
            <div style={{textAlign: 'center'}}>
                <Header/>
                <p>お客様のメールアドレス宛に確認メールを送信しました。メール内のリンクを押していただくと登録完了となります。</p>
                <p>もしメールが届かない場合は、迷惑メールフォルダをご確認いただくようお願いいたします。</p>
                <p>再度確認メールを送信する場合、下記ボタンを押してください。</p>
                <button onClick={resendVerificationEmail}>
                    確認メールを再送信
                </button>
                {emailSent && <p>認証メールが送信されました！</p>}

                <p style={{marginTop:"40px"}}>別のメールアドレスで登録する場合は下記を押してください。</p>
                <button onClick={handleLogout}>
                    ログアウト
                </button>
                <Footer/>
            </div>
        );
    }

    return ( // ログイン済みの場合
        <div style={{textAlign: 'center'}} className={isVip ? "gaming" : ""}>
            <Header/>
            <div
                style={{marginBottom: '20px', fontSize: '2em'}}
            >ようこそ, {isVip ? "【VIP】" : ""}{userData ? userData.name : '読み込み中...'}さん
            </div>
            <div><Link to="/requestvaluation" className="menu-btn">買取依頼</Link></div>
            <br/>
            <div><Link to="/consignmentsales" className="menu-btn">委託販売依頼</Link></div>

            <br/>
            <br/>
            <div><Link to="/profile" className="menu" style={{fontSize: "18px"}}>会員情報</Link></div>
            <br/>
            <div><Link to="/passwordchange" className="menu" style={{fontSize: "18px"}}>パスワード変更</Link></div>
            <br/>
            <div><Link to="/logout" className="menu" style={{fontSize: "18px"}}>ログアウト</Link></div>
            <Footer/>
        </div>
    );
};

export default Top;
