import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {storage, auth, db} from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Footer from "./Footer";
import {
    yearOptions,
    monthOptions,
    dayOptions,
    formatToTwoDigits,
    formatMonthDayToNumber,
    generateRandomStr,
    validateFileSize
} from './common';

const ProfileEdit = () => {
    const [userData, setUserData] = useState(null);
    const [storeName, setStoreName] = useState("");
    const [name, setName] = useState("");
    const [kana, setKana] = useState("");
    const [gender, setGender] = useState('');
    const [birthYear, setBirthYear] = useState("");
    const [birthMonth, setBirthMonth] = useState("");
    const [birthDay, setBirthDay] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [addressOrg, setAddressOrg] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [job, setJob] = useState("");
    const [idVerifyDocs, setIdVerifyDocs] = useState("");
    const [bankName, setBankName] = useState("");
    const [bankBranchName, setBankBranchName] = useState("");
    const [bankBranchCode, setBankBranchCode] = useState("");
    const [bankAccountName, setBankAccountName] = useState("");
    const [bankAccountNumber, setBankAccountNumber] = useState("");
    const [invoiceIssuer, setInvoiceIssuer] = useState(""); // 適格請求書発行事業者 invoiceIssuerYes: はい, invoiceIssuerNo: いいえ
    const [invoiceIssuerNumber, setInvoiceIssuerNumber] = useState(""); // 適格請求書発行事業者番号
    const [file1, setFile1] = useState(null); // 身分証ファイル
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
    const [isReceivingAddress, setIsReceivingAddress] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState("");
    const navigate = useNavigate();

    // ユーザー情報取得
    useEffect(() => {
        const fetchUserData = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                navigate('/'); // 未ログインの場合、トップページへリダイレクト
                return;
            }
            const docRef = doc(db, "users", currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists) {
                navigate('/'); // 未ログインの場合、トップページへリダイレクト
                return;
            }
            const tempUserData = docSnap.data();
            if (!tempUserData.userType) {
                tempUserData.userType = 'userTypeInd';
            }
            setUserData(tempUserData);
            setStoreName(tempUserData.storeName);
            setName(tempUserData.name);
            setKana(tempUserData.kana);
            if (tempUserData.invoiceIssuer === undefined) {
                tempUserData.invoiceIssuer = "invoiceIssuerNo";
            }
            setInvoiceIssuer(tempUserData.invoiceIssuer);
            setInvoiceIssuerNumber(tempUserData.invoiceIssuerNumber);
            setGender(tempUserData.gender);
            if (tempUserData.birthDate && tempUserData.birthDate.length === 8) {
                setBirthYear(tempUserData.birthDate.slice(0, 4));
                setBirthMonth(formatMonthDayToNumber(tempUserData.birthDate.slice(4, 6)));
                setBirthDay(formatMonthDayToNumber(tempUserData.birthDate.slice(6, 8)));
            }
            setJob(tempUserData.job);
            setIdVerifyDocs(tempUserData.idVerifyDocs);
            setZipcode(tempUserData.zipcode);
            setAddress(tempUserData.address);
            setPhone(tempUserData.phone);
            setBankName(tempUserData.bankName);
            setBankBranchName(tempUserData.bankBranchName);
            setBankBranchCode(tempUserData.bankBranchCode);
            setBankAccountNumber(tempUserData.bankAccountNumber);
            setBankAccountName(tempUserData.bankAccountName);
        };

        fetchUserData().then();
    }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = async (event) => {
        const inputValue = event.target.value;
        setZipcode(inputValue);

        // 7桁の郵便番号が入力されたらAPIを呼び出す
        if (inputValue.length === 7 && /^\d{7}$/.test(inputValue)) {
            setIsReceivingAddress(true);
            try {
                const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${inputValue}`);
                const data = await response.json();

                if (data.status === 200 && data.results) {
                    const adr = data.results[0];
                    setAddress(`${adr['address1']}${adr['address2']}${adr['address3']}`);
                    setAddressOrg(`${adr['address1']}${adr['address2']}${adr['address3']}`);
                } else {
                    setError('該当する郵便番号は見つかりませんでした。');
                    setAddress('');
                    setAddressOrg('');
                }
            } catch (error) {
                setError('エラーが発生しました。もう一度試してください。');
                setAddress('');
                setAddressOrg('');
            } finally {
                setIsReceivingAddress(false);
            }
        } else {
            setAddress('');
            setAddressOrg('');
        }
    };

    const getUploadedUrl = (uploadedUrls, index) => {
        try {
            let uploadedUrl = uploadedUrls[index];
            if (!uploadedUrl) {
                uploadedUrl = "";
            }
            return uploadedUrl;
        } catch (err) {
            return "";
        }
    }

    const checkRequired = (value, name) => {
        if (!value) {
            setError(name + "を入力してください");
            setIsSubmitting(false);
            return true;
        }
        return false;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            if (userData.userType === "userTypeInd") {
                if (checkRequired(name, "氏名")) {
                    return;
                }
                if (checkRequired(kana, "氏名(カナ)")) {
                    return;
                }
                if (checkRequired(gender, "性別")) {
                    return;
                }
                if (checkRequired(job, "ご職業")) {
                    return;
                }
                if (checkRequired(idVerifyDocs, "身分証")) {
                    return;
                }

            } else if (userData.userType === "userTypeCorp") {
                if (checkRequired(storeName, "法人名")) {
                    return;
                }
                if (checkRequired(name, "担当者名")) {
                    return;
                }
                if (checkRequired(kana, "担当者名(カナ)")) {
                    return;
                }
            }

            if (checkRequired(address, "住所")) {
                return;
            }
            if (checkRequired(phone, "電話番号")) {
                return;
            }
            if (addressOrg === address) { // 住所
                setError("住所の番地が入力されていません");
                setIsSubmitting(false);
                return;
            }
            if (invoiceIssuer === "invoiceIssuerYes" && invoiceIssuerNumber.length < 13) { // インボイス番号が13桁未満
                setError("インボイス番号が正しくありません");
                setIsSubmitting(false);
                return;
            }

            // ファイルサイズチェック
            const files = [file1, file2, file3].filter(file => file !== null);
            if (files.some(file => !validateFileSize(file, MAX_FILE_SIZE))) {
                setError("登録に失敗しました: ファイルサイズが"+MAX_FILE_SIZE+"MBを超えています");
                return;
            }

            // 画像ファイルをアップロード
            const currentUser = auth.currentUser;
            if (file1) {
                const storageRef = ref(storage, `id_documents/${currentUser.uid}_${generateRandomStr(6)}_${file1.name}`);
                const uploadTask = uploadBytes(storageRef, file1).then(snapshot => {
                    return getDownloadURL(snapshot.ref);
                });
                const uploadedUrls = await Promise.all([uploadTask]);
                userData.idDocUrl1 = getUploadedUrl(uploadedUrls, 0);
            }
            if (file2) {
                const storageRef = ref(storage, `id_documents/${currentUser.uid}_${generateRandomStr(6)}_${file2.name}`);
                const uploadTask = uploadBytes(storageRef, file2).then(snapshot => {
                    return getDownloadURL(snapshot.ref);
                });
                const uploadedUrls = await Promise.all([uploadTask]);
                userData.idDocUrl2 = getUploadedUrl(uploadedUrls, 0);
            }
            if (file3) {
                const storageRef = ref(storage, `id_documents/${currentUser.uid}_${generateRandomStr(6)}_${file3.name}`);
                const uploadTask = uploadBytes(storageRef, file3).then(snapshot => {
                    return getDownloadURL(snapshot.ref);
                });
                const uploadedUrls = await Promise.all([uploadTask]);
                userData.idDocUrl3 = getUploadedUrl(uploadedUrls, 0);
            }

            // Firestoreにユーザーデータを保存
            userData.name = name;
            userData.kana = kana;
            userData.zipcode = zipcode;
            userData.address = address;
            userData.phone = phone;
            userData.invoiceIssuer = invoiceIssuer;
            userData.invoiceIssuerNumber = invoiceIssuerNumber ? invoiceIssuerNumber : "";
            userData.bankName = bankName ? bankName : "";
            userData.bankBranchName = bankBranchName ? bankBranchName : "";
            userData.bankBranchCode = bankBranchCode ? bankBranchCode : "";
            userData.bankAccountNumber = bankAccountNumber ? bankAccountNumber : "";
            userData.bankAccountName = bankAccountName ? bankAccountName : "";

            // 個人の場合
            if (userData.userType === "userTypeInd") {
                userData.gender = gender;
                userData.birthDate = String(birthYear) + formatToTwoDigits(birthMonth) + formatToTwoDigits(birthDay);
                userData.job = job;
                userData.idVerifyDocs = idVerifyDocs;
                userData.storeName = "";
            } else {
                userData.gender = "";
                userData.birthDate = "";
                userData.job = "";
                userData.idVerifyDocs = "";
                userData.storeName = storeName;
            }
            userData.updatedAt = new Date().toISOString();
            console.log(userData);

            const userDocRef = doc(db, 'users', currentUser.uid);
            await updateDoc(userDocRef, userData);

        } catch (err) {
            setError("登録に失敗しました: " + err.message);
            console.log("登録に失敗しました: " + err.message);
            setIsSubmitting(false);
        }

        navigate("/profile");
    };

    return (
        <div>
            {userData ? (
                <form>
                    <div className="container mt-5">
                        <h2 style={{textAlign: 'center', marginBottom: '10px'}}>会員情報変更</h2>
                    </div>
                    <div className="container mt-5">
                        <table className="table table-bordered">
                            <tbody>
                            <tr>
                                <th>会員登録日</th>
                                <td>{userData.createdAt}</td>
                            </tr>
                            <tr>
                                <th>メールアドレス</th>
                                <td>{userData.email}</td>
                            </tr>
                            <tr>
                                <th>名義</th>
                                <td>
                                    {userData.userType === 'userTypeInd' ? '個人' :
                                        userData.userType === 'userTypeCorp' ? '法人' : ''}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25 required">適格請求書発行事業者</th>
                                <td>
                                    <input
                                        type="radio"
                                        name="invoiceIssuer"
                                        id="invoiceIssuerYes"
                                        value="invoiceIssuerYes"
                                        checked={invoiceIssuer === "invoiceIssuerYes"}
                                        onChange={(e) => setInvoiceIssuer(e.target.value)}
                                        required={true}
                                    />
                                    <label htmlFor={"invoiceIssuerYes"} style={{marginRight: '10px'}}>はい</label>
                                    {userData.userType === "userTypeInd" && (
                                        <>
                                            <input
                                                type="radio"
                                                name="invoiceIssuer"
                                                id="invoiceIssuerNo"
                                                value="invoiceIssuerNo"
                                                checked={invoiceIssuer === "invoiceIssuerNo"}
                                                onChange={(e) => setInvoiceIssuer(e.target.value)}
                                                required={true}
                                            />
                                            <label htmlFor={"invoiceIssuerNo"}>いいえ</label>
                                        </>
                                    )}
                                    <div>※申請手続をしていない個人の方は「いいえ」を選択してください</div>
                                </td>
                            </tr>
                            <tr>
                                <th className={invoiceIssuer === "invoiceIssuerYes" ? "w-25 required" : "w-25"}>インボイス番号</th>
                                <td>
                                    <input
                                        type="text"
                                        value={invoiceIssuerNumber}
                                        onChange={(e) => setInvoiceIssuerNumber(e.target.value)}
                                        placeholder="例:T1234567890123"
                                        style={{width: '100%'}}
                                        required={invoiceIssuer === "invoiceIssuerYes"}
                                    />
                                </td>
                            </tr>
                            {userData.userType === "userTypeInd" && ( // 個人の場合のフォーム
                                <>
                                    <tr>
                                        <th className="w-25 required">氏名</th>
                                        <td>
                                            <input
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="例:山田太郎"
                                                required={true}
                                                style={{width: '100%'}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="required">氏名(カナ)</th>
                                        <td>
                                            <input
                                                type="text"
                                                value={kana}
                                                onChange={(e) => setKana(e.target.value)}
                                                placeholder="例:ヤマダタロウ"
                                                required={true}
                                                style={{width: '100%'}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="required">性別</th>
                                        <td>
                                            <select value={gender} onChange={(e) => setGender(e.target.value)}
                                                    required={true}>
                                                <option value="" disabled>選択してください</option>
                                                <option value="f">女性</option>
                                                <option value="m">男性</option>
                                                <option value="o">その他</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="required">生年月日</th>
                                        <td>
                                            <div className="row">
                                                <div className="col-2 date_select_box">
                                                    <select value={birthYear}
                                                            onChange={(e) => setBirthYear(e.target.value)}
                                                            required={true}>
                                                        {yearOptions.map(year => (
                                                            <option key={year} value={year}>{year}年</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-2 date_select_box">
                                                    <select value={birthMonth}
                                                            onChange={(e) => setBirthMonth(e.target.value)}
                                                            required={true}>
                                                        {monthOptions.map(month => (
                                                            <option key={month} value={month}>{month}月</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-2 date_select_box">
                                                    <select value={birthDay}
                                                            onChange={(e) => setBirthDay(e.target.value)}
                                                            required={true}>
                                                        {dayOptions.map(day => (
                                                            <option key={day} value={day}>{day}日</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="required">ご職業</th>
                                        <td>
                                            <select
                                                value={job}
                                                onChange={(e) => setJob(e.target.value)}
                                                required={true}
                                            >
                                                <option value="" disabled>選択してください</option>
                                                <option value="company_employee">会社員</option>
                                                <option value="contract_employee">契約社員・派遣社員</option>
                                                <option value="part_time">パート・アルバイト</option>
                                                <option value="public_servant">公務員</option>
                                                <option value="self_employed">自営業</option>
                                                <option value="homemaker">専業主婦・主夫</option>
                                                <option value="student">学生</option>
                                                <option value="unemployed">無職</option>
                                                <option value="other">その他</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="required">身分証</th>
                                        <td>
                                            <select
                                                value={idVerifyDocs}
                                                onChange={(e) => setIdVerifyDocs(e.target.value)}
                                                required={true}
                                            >
                                                <option value="" disabled>選択してください</option>
                                                <option value="driver">運転免許証</option>
                                                <option value="health">健康保険証</option>
                                                <option value="student">学生証(顔写真があるもの)</option>
                                                <option value="passport">パスポート</option>
                                                <option value="special_residence">特別永住者証明書</option>
                                                <option value="my_number">マイナンバーカード</option>
                                                <option value="juuki">住民基本カード</option>
                                                <option value="driver2">運転経歴証明書</option>
                                                <option value="other">その他</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>身分証画像1</th>
                                        <td>
                                            {userData.idDocUrl1 && (
                                                <img src={userData.idDocUrl1} alt="身分証画像1"
                                                     style={{width: "300px", height: "160px"}}/>
                                            )}
                                            <input type="file" onChange={(e) => setFile1(e.target.files[0])}
                                                   accept=".png, .jpeg, .jpg, .gif"/>
                                            <div style={{color: "red"}}>※店頭買取の場合はアップロード不要です</div>
                                            <div>※裏面もある場合は裏面の画像もアップロード</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>身分証画像2</th>
                                        <td>
                                            {userData.idDocUrl2 && (
                                                <img src={userData.idDocUrl2} alt="身分証画像2"
                                                     style={{width: "300px", height: "160px"}}/>
                                            )}
                                            <input type="file" onChange={(e) => setFile2(e.target.files[0])}
                                                   accept=".png, .jpeg, .jpg, .gif"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>身分証画像3</th>
                                        <td>
                                            {userData.idDocUrl3 && (
                                                <img src={userData.idDocUrl3} alt="身分証画像3"
                                                     style={{width: "300px", height: "160px"}}/>
                                            )}
                                            <input type="file" onChange={(e) => setFile3(e.target.files[0])}
                                                   accept=".png, .jpeg, .jpg, .gif"/>
                                        </td>
                                    </tr>
                                </>
                            )}
                            {userData.userType === "userTypeCorp" && ( // 法人の場合のフォーム
                                <>
                                    <tr>
                                        <th className="w-25 required">法人名</th>
                                        <td>
                                            <input
                                                type="text"
                                                value={storeName}
                                                onChange={(e) => setStoreName(e.target.value)}
                                                placeholder="例:○○株式会社"
                                                required={true}
                                                style={{width: '100%'}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="w-25 required">担当者名</th>
                                        <td>
                                            <input
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="例:山田太郎"
                                                required={true}
                                                style={{width: '100%'}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="required">担当者名(カナ)</th>
                                        <td>
                                            <input
                                                type="text"
                                                value={kana}
                                                onChange={(e) => setKana(e.target.value)}
                                                placeholder="例:ヤマダタロウ"
                                                required={true}
                                                style={{width: '100%'}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="required" colSpan="2">登記簿謄本、担当者様の名刺の画像</th>
                                    </tr>
                                    <tr>
                                        <th>画像1</th>
                                        <td>
                                            {userData.idDocUrl1 && (
                                                <img src={userData.idDocUrl1} alt="画像1"
                                                     style={{width: "300px", height: "160px"}}/>
                                            )}
                                            <input type="file" onChange={(e) => setFile1(e.target.files[0])}
                                                   accept=".png, .jpeg, .jpg, .gif"/>
                                            <div style={{color: "red"}}>※店頭買取の場合はアップロード不要です</div>
                                            <div>※裏面もある場合は裏面の画像もアップロードしてください</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>画像2</th>
                                        <td>
                                            {userData.idDocUrl2 && (
                                                <img src={userData.idDocUrl2} alt="画像2"
                                                     style={{width: "300px", height: "160px"}}/>
                                            )}
                                            <input type="file" onChange={(e) => setFile2(e.target.files[0])}
                                                   accept=".png, .jpeg, .jpg, .gif"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>画像3</th>
                                        <td>
                                            {userData.idDocUrl3 && (
                                                <img src={userData.idDocUrl3} alt="画像3"
                                                     style={{width: "300px", height: "160px"}}/>
                                            )}
                                            <input type="file" onChange={(e) => setFile3(e.target.files[0])}
                                                   accept=".png, .jpeg, .jpg, .gif"/>
                                        </td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <th className="required">住所</th>
                                <td>
                                    <input
                                        type="text"
                                        pattern={"[0-9]+"}
                                        value={zipcode}
                                        onChange={handleChange}
                                        placeholder="郵便番号を入力"
                                        maxLength={7}
                                        required={true}
                                    />※ハイフンなし
                                    <br></br>
                                    <input
                                        type="text"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        placeholder="住所を入力"
                                        maxLength={200}
                                        style={{width: '100%', marginTop: '10px'}}
                                        required={true}
                                        disabled={isReceivingAddress}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th className="required">電話番号</th>
                                <td>
                                    <input
                                        type="text"
                                        pattern={"[0-9]+"}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="電話番号を入力"
                                        maxLength={15}
                                        required={true}
                                    />※ハイフンなし
                                </td>
                            </tr>
                            <tr>
                                <th colSpan="2">振込先情報 ※現金払いをご希望の場合は入力不要です　必ずご本人名義の口座を登録してください</th>
                            </tr>
                            <tr>
                                <th>銀行名</th>
                                <td>
                                    <input
                                        type="text"
                                        value={bankName}
                                        onChange={(e) => setBankName(e.target.value)}
                                        placeholder="例:○○銀行"
                                        style={{width: '100%'}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>支店名</th>
                                <td>
                                    <input
                                        type="text"
                                        value={bankBranchName}
                                        onChange={(e) => setBankBranchName(e.target.value)}
                                        placeholder="例:○○支店"
                                        style={{width: '100%'}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>支店コード</th>
                                <td>
                                    <input
                                        type="text"
                                        value={bankBranchCode}
                                        onChange={(e) => setBankBranchCode(e.target.value)}
                                        placeholder="例:001"
                                        style={{width: '100%'}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>口座番号</th>
                                <td>
                                    <input
                                        type="text"
                                        value={bankAccountNumber}
                                        onChange={(e) => setBankAccountNumber(e.target.value)}
                                        placeholder="例:1234567"
                                        style={{width: '100%'}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>口座名義(カタカナ)</th>
                                <td>
                                    <input
                                        type="text"
                                        value={bankAccountName}
                                        onChange={(e) => setBankAccountName(e.target.value)}
                                        placeholder="例:ヤマダタロウ"
                                        style={{width: '100%'}}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        {error && <p style={{color: "red", fontWeight: "bold"}}>{error}</p>}
                        <br/>
                        <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >登録
                        </button>
                    </div>
                </form>
            ) : (
                <p>データの取得中...</p>
            )}
            <Footer/>
        </div>
    );
};

export default ProfileEdit;
