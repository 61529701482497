import { useEffect } from 'react'; // useEffectをインポート
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';

function Logout() {
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLogout = async () => {
        await auth.signOut();
        navigate('/');
    };

    // コンポーネントがマウントされた時にhandleLogoutを実行
    useEffect(() => {
        handleLogout().then();
    }, [handleLogout, navigate]);

}

export default Logout;
