import React from "react";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className="footer" style={{ textAlign: 'center', marginTop: '40px' }}>
            <hr></hr>
            <span className="footer_link">
                <Link to="/">TOP</Link> ｜
                <Link to="https://www.cardshop-shinsoku.jp/">通販</Link> ｜
                {/*<Link to="/access">アクセス</Link> ｜*/}
                {/*<a href="https://example.com/1gocard#pj3">What’s NEW！</a> ｜*/}
                <a href="https://twitter.com/shinsoku_akiba" target="_blank" rel="noopener noreferrer">X(Twitter)</a> ｜
                <Link to="/kiyaku">サイトご利用規約</Link> ｜
                {/*<a href="https://example.com/1gocard/rule/">個人情報保護方針</a> ｜*/}
                <Link to="/privacypolicy">プライバシーポリシー</Link>
            </span>
        </div>
    );
}

export default Footer;