import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Kiyaku() {
    return (
        <div style={{textAlign: 'center'}}>
            <Header />
            <p>JR秋葉原駅より 徒歩6分</p>
            <p>東京メトロ銀座線 末広町駅より 徒歩1分</p>
            <img src={process.env.PUBLIC_URL + '/access_1.png'} className="access-image" alt="shinsoku_access" />
            <Footer />
        </div>
    );
}

export default Kiyaku;