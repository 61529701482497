// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { auth } from '../firebaseConfig';

import Footer from "./Footer";
import Header from "./Header";

const RegisterComplete = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Header />
            <h2>仮登録完了</h2>
            <p>お客様のメールアドレス宛に確認メールを送信しました。メール内のリンクを押していただくと登録完了となります。</p>
            <p>もしメールが届かない場合は、迷惑メールフォルダをご確認いただくようお願いいたします。</p>

            <Footer />
        </div>
    );
};

export default RegisterComplete;
