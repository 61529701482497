import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { updatePassword } from "firebase/auth";
import Header from "./Header";
import {useNavigate} from "react-router-dom";
import Footer from "./Footer";

const PasswordChange = () => {
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handlePasswordChange = async (event) => {
        event.preventDefault();
        if (!newPassword) {
            setError("Please enter a new password.");
            return;
        }
        try {
            await updatePassword(auth.currentUser, newPassword);
            navigate('/');
        } catch (error) {
            setError("パスワード変更失敗: " + error.message);
            setMessage('');
        }
    };

    return (
        <div style={{textAlign: 'center'}}>
            <Header/>
            <h2>パスワード変更</h2>
            <form onSubmit={handlePasswordChange}>
                <div className="container mt-5">
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <th>新しいパスワード</th>
                            <td>
                                <input
                                    type="password"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    style={{width: '100%'}}
                                    required
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <button type="submit" style={{marginTop: '20px'}}>パスワード変更</button>
            </form>
            {error && <p>{error}</p>}
            {message && <p>{message}</p>}
            <Footer/>
        </div>
    );
};

export default PasswordChange;
