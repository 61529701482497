import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA8SUrMNW7swFuyOXhN92syyUO3FUXRYbw",
    authDomain: "shinsoku-members-site.firebaseapp.com",
    projectId: "shinsoku-members-site",
    storageBucket: "shinsoku-members-site.appspot.com",
    messagingSenderId: "444628394924",
    appId: "1:444628394924:web:8a96d564ab01c1c53e9009",
    measurementId: "G-7TFJ87E7RP"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { storage, auth, db };
