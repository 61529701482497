import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import KiyakuNoHeader from "./KiyakuNoHeader";

function Kiyaku() {
    return (
        <div>
            <Header />
            <KiyakuNoHeader />
            <Footer />
        </div>
    );
}

export default Kiyaku;