import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Header from "./Header";
import Footer from "./Footer";

function LoginPassword() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/');
        } catch (err) {
            if (err.message.indexOf("invalid-login-credentials")) {
                setMessage("ログインに失敗しました: メールアドレスまたはパスワードが正しくありません。");
            } else {
                setMessage("ログインに失敗しました: " + err.message);
            }
        }
    };

    return (
        <div style={{textAlign: 'center'}}>
            <Header/>
            <h2>ログイン</h2>
            <div className="container mt-5">
                <table className="table table-bordered">
                    <tbody>
                    <tr>
                        <th>メールアドレス</th>
                        <td>
                            <input
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                style={{width: '100%'}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>パスワード</th>
                        <td>
                            <input
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                style={{width: '100%'}}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <br/>
            <div style={{color:"red"}}>
                <p>2024年5月22日以前に登録した方へ</p>
                <p>メールアドレスのみでログインする方式からメールアドレスとパスワードでログインする方式に変更となりました</p>
                <p>下の【パスワードを忘れた場合はこちら】より設定をお願いいたします</p>
            </div>
            <br/>
            <Link to="/passwordreset">パスワードを忘れた場合はこちら</Link>
            <br/>
            <button style={{marginTop: '20px'}} onClick={handleLogin}>ログイン</button>
            {message && <div>{message}</div>}
            <Footer/>
        </div>
    );
}

export default LoginPassword;
