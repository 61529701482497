import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {auth} from "../firebaseConfig";
import Footer from "./Footer";

const RequestValuation = () => {
    const [isAgreed, setIsAgreed] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                // 未ログインの場合、トップページへリダイレクト
                navigate('/');
            }
        };

        fetchUserData().then();
    }, [navigate]);

    const handleAgreementChange = (e) => {
        setIsAgreed(e.target.checked);
    };

    const handleSubmit = () => {
        navigate("/profile");
    };

    return (
        <div>
            <div className="container mt-5">
                <h2 style={{textAlign: 'center'}}>買取の流れ</h2>
                <br/>
                <h4>１．下記の「買取確認事項」を確認し、同意するをチェックをしてください。</h4>
                <h4>２．同意後に表示されるバーコードを当店の店員にご提示ください。</h4>
                <h4>３．その際、下記も一緒にご提出ください。</h4>
                <ul>
                    <li>買取依頼する商品 ※素体はスリーブを外して店内の仕切りケースに入れてください</li>
                    <li>本人確認書類 ※会員登録時に選択した書類をご提示ください</li>
                    <li>18才未満の場合、保護者同意書</li>
                </ul>
                <br/>
                <h2 style={{textAlign: 'center'}}>買取確認事項</h2>
                <ul>
                    <li>査定担当が査定に必要な作業を行うことに同意します。</li>
                    <li>買取依頼する商品は、全て私の所有物です。</li>
                    <li>会員登録した情報（住所、氏名、電話番号など）に間違いはありません。</li>

                    <li>18才以上です。※18未満の場合、保護者同意書が必要、但し小学生以下は買取不可</li>
                    {/*<li>適格請求書発行事業者ではありません。※適格請求書発行事業者の場合、買取不可</li>*/}

                    <li>警察に会員情報または査定情報等の提出を求められた場合、提出することに異存ありません。</li>
                    <li>買取依頼された日に買取が完了できない場合、持ち込んだ査定品の所有権を全て破棄することに同意します。</li>
                </ul>
                <br />
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={isAgreed}
                            onChange={handleAgreementChange}
                        />
                        上記確認事項に同意して買取依頼します。
                    </label>
                </div>
                <div style={{textAlign: 'center'}}>
                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSubmit}
                        disabled={!isAgreed}
                    >
                        買取依頼
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default RequestValuation;
