import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {auth, db} from '../firebaseConfig';
import {doc, getDoc} from "firebase/firestore";
import Footer from "./Footer";
import QRCode from 'qrcode';

const Profile = () => {
    const [userData, setUserData] = useState(null);
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const navigate = useNavigate();
    // const barcodeRef = useRef(null); // バーコードを描画するためのref

    useEffect(() => {
        const fetchUserData = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                navigate('/'); // 未ログインの場合、トップページへリダイレクト
                return;
            }
            const docRef = doc(db, "users", currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists) {
                navigate('/'); // 未ログインの場合、トップページへリダイレクト
                return;
            }
            const tempUserData = docSnap.data();
            if (!tempUserData.userType) {
                tempUserData.userType = 'userTypeInd';
            }
            setUserData(tempUserData);
        };

        fetchUserData().then();
    }, [navigate]);

    // const encodeToBase64 = (str) => {
    //     // 文字列をURIコンポーネントとしてエンコード
    //     const uriEncodedStr = encodeURIComponent(str);
    //     // エンコードされた文字列をBase64に変換
    //     return btoa(uriEncodedStr);
    // }

    // バーコードを生成する useEffect
    useEffect(() => {
        // 2次元
        if (userData && userData.email) {
            const forQrUserData = {
                e: userData.email,
                // n: encodeToBase64(userData.name),
                // k: userData.kana,
                // g: userData.gender,
                // b: userData.birthDate,
                // z: userData.zipcode,
                // a: userData.address,
                // p: userData.phone,
                // j: userData.job,
                // i: userData.idVerifyDocs
            }
            // console.log(userData);
            // console.log(JSON.stringify(forQrUserData));
            QRCode.toDataURL(JSON.stringify(forQrUserData)).then(url => {
                setQrCodeUrl(url)
            })
            .catch(err => {
                console.error('QRコードの生成に失敗しました: ', err);
            });
        }
    }, [userData]); // userDataが変更されたら再描画

    const hundlerImgClick = (e) => {
        const whStyle = e.target.style.width;
        if (whStyle === "300px") {
            e.target.style.width = "600px";
            e.target.style.height = "600px";
        } else {
            e.target.style.width = "300px";
            e.target.style.height = "300px";
        }
    }

    return (
        <div>
            {userData ? (
                <>
                <div className="container mt-5">
                    <Link to="/profileedit">会員情報の変更はこちら</Link>
                    <h2 style={{textAlign: 'center'}}>会員情報</h2>
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <th>バーコード</th>
                            <td>
                                {/*<canvas ref={barcodeRef} />*/}
                                <img style={{width: "300px", height: "300px"}} onClick={hundlerImgClick} src={qrCodeUrl}
                                     alt="Generated QR Code"/>
                            </td>
                        </tr>
                        <tr>
                            <th>会員登録日</th>
                            <td>{userData.createdAt}</td>
                        </tr>
                        <tr>
                            <th>メールアドレス</th>
                            <td>{userData.email}</td>
                        </tr>
                        <tr>
                            <th>名義</th>
                            <td>
                                {userData.userType === 'userTypeInd' ? '個人' :
                                    userData.userType === 'userTypeCorp' ? '法人' : ''}
                            </td>
                        </tr>
                        <tr>
                            <th>適格請求書発行事業者</th>
                            <td>
                                {userData.invoiceIssuer === 'invoiceIssuerYes' ? 'はい' :
                                    userData.invoiceIssuer === 'invoiceIssuerNo' ? 'いいえ' : ''}
                            </td>
                        </tr>
                        <tr>
                            <th>インボイス番号</th>
                            <td>{userData.invoiceIssuerNumber}</td>
                        </tr>
                        {userData.userType === 'userTypeInd' && ( // 個人の場合のフォーム
                            <>
                                <tr>
                                    <th>氏名</th>
                                    <td>{userData.name}</td>
                                </tr>
                                <tr>
                                    <th>氏名(カナ)</th>
                                    <td>{userData.kana}</td>
                                </tr>
                                <tr>
                                    <th>性別</th>
                                    <td>
                                        {userData.gender === 'm' ? '男性' :
                                            userData.gender === 'f' ? '女性' :
                                                userData.gender === 'o' ? 'その他' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th>生年月日</th>
                                    <td>{userData.birthDate}</td>
                                </tr>
                                <tr>
                                    <th>ご職業</th>
                                    <td>
                                        {userData.job === 'company_employee' ? '会社員' :
                                            userData.job === 'contract_employee' ? '契約社員・派遣社員' :
                                                userData.job === 'part_time' ? 'パート・アルバイト' :
                                                    userData.job === 'public_servant' ? '公務員' :
                                                        userData.job === 'self_employed' ? '自営業' :
                                                            userData.job === 'homemaker' ? '専業主婦・主夫' :
                                                                userData.job === 'student' ? '学生' :
                                                                    userData.job === 'unemployed' ? '無職' :
                                                                        userData.job === 'other' ? 'その他' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th>身分証</th>
                                    <td>
                                        {userData.idVerifyDocs === 'driver' ? '運転免許証' :
                                            userData.idVerifyDocs === 'health' ? '健康保険証' :
                                                userData.idVerifyDocs === 'student' ? '学生証(顔写真があるもの)' :
                                                    userData.idVerifyDocs === 'passport' ? 'パスポート' :
                                                        userData.idVerifyDocs === 'special_residence' ? '特別永住者証明書' :
                                                            userData.idVerifyDocs === 'my_number' ? 'マイナンバーカード' :
                                                                userData.idVerifyDocs === 'juuki' ? '住民基本カード' :
                                                                    userData.idVerifyDocs === 'driver2' ? '運転経歴証明書' :
                                                                        userData.idVerifyDocs === 'other' ? 'その他' : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th>身分証画像1</th>
                                    <td>
                                        {userData.idDocUrl1 && (
                                            <img src={userData.idDocUrl1} alt="身分証画像1" style={{width:"300px", height:"160px"}}/>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>身分証画像2</th>
                                    <td>
                                        {userData.idDocUrl2 && (
                                            <img src={userData.idDocUrl2} alt="身分証画像2" style={{width:"300px", height:"160px"}}/>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>身分証画像3</th>
                                    <td>
                                        {userData.idDocUrl3 && (
                                            <img src={userData.idDocUrl3} alt="身分証画像3" style={{width:"300px", height:"160px"}}/>
                                        )}
                                    </td>
                                </tr>
                            </>
                        )}
                        {userData.userType === "userTypeCorp" && ( // 法人の場合のフォーム
                            <>
                                <tr>
                                    <th>法人名</th>
                                    <td>{userData.storeName}</td>
                                </tr>
                                <tr>
                                    <th>担当者名</th>
                                    <td>{userData.name}</td>
                                </tr>
                                <tr>
                                    <th>担当者名(カナ)</th>
                                    <td>{userData.kana}</td>
                                </tr>
                                <tr>
                                    <th>画像1</th>
                                    <td>
                                        {userData.idDocUrl1 && (
                                            <img src={userData.idDocUrl1} alt="画像1" style={{width:"300px", height:"160px"}}/>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>画像2</th>
                                    <td>
                                        {userData.idDocUrl2 && (
                                            <img src={userData.idDocUrl2} alt="画像2" style={{width:"300px", height:"160px"}}/>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>画像3</th>
                                    <td>
                                        {userData.idDocUrl3 && (
                                            <img src={userData.idDocUrl3} alt="画像3" style={{width:"300px", height:"160px"}}/>
                                        )}
                                    </td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <th>住所</th>
                            <td>{userData.zipcode} {userData.address}</td>
                        </tr>
                        <tr>
                            <th>電話番号</th>
                            <td>{userData.phone}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">振込先情報</th>
                        </tr>
                        <tr>
                            <th>銀行名</th>
                            <td>{userData.bankName}</td>
                        </tr>
                        <tr>
                            <th>支店名</th>
                            <td>{userData.bankBranchName}</td>
                        </tr>
                        <tr>
                            <th>支店コード</th>
                            <td>{userData.bankBranchCode}</td>
                        </tr>
                        <tr>
                            <th>口座番号</th>
                            <td>{userData.bankAccountNumber}</td>
                        </tr>
                        <tr>
                            <th>口座名義(カタカナ)</th>
                            <td>{userData.bankAccountName}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                </>
            ) : (
                <p>データの取得中...</p>
            )}
            <Footer/>
        </div>
    );
};

export default Profile;
